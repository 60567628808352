export const AnalyticEvents = {
  CHANGE_SETTINGS_TAB: 'change_settings_tab',
  PAGE_VIEW: 'page_view',
  FRONTEND_ERROR: 'frontend_error',
  VIEW_IN_STORE_BUTTON: 'view_in_store_button',
  LEARN_MORE_SUBSCRIPTION: 'learn_more_subscription',
  SEND_TEST_EMAIL_BUTTON: 'send_test_email_button',
  GO_TO_DASHBOARD_HYPERLINK: 'go_to_dashboard_hyperlink',
  MANAGE_SERVICE_HYPERLINK: 'manage_service_hyperlink',
  READ_FAQ_HYPERLINK: 'read_faq_hyperlink',
  IMAGE_LINK_INPUT: 'image_link_input',
  CHANGE_LANGUAGE: 'change_language',

  CREATE_NOTIFICATION: 'create_notification',
  EDIT_NOTIFICATION: 'edit_notification',
  DELETE_NOTIFICATION: 'delete_notification',
  TOGGLE_NOTIFICATION_CONFIRMATION: 'toggle_notification_confirmation',
  TOGGLE_NOTIFICATION_REMINDER: 'toggle_notification_reminder',
  TOGGLE_NOTIFICATION_RESCHEDULE: 'toggle_notification_reschedule',
  TOGGLE_NOTIFICATION_CANCELATION: 'toggle_notification_cancelation',
  TOGGLE_NOTIFICATION_CALENDAR_INTEGRATION:
    'toggle_notification_calendar_integration',
  ADD_SERVICE: 'add_service',
  MANAGE_SERVICE: 'manage_service',
  REMOVE_SERVICE: 'remove_service',
  COPY_CONFIG: 'copy_config',
  SET_SERVICE_AS_ACTIVE_BULK_EDIT: 'set_service_as_active_bulk_edit',
  SET_SERVICE_AS_DRAFT_BULK_EDIT: 'set_service_as_draft_bulk_edit',
  REMOVE_BULK_EDIT: 'remove_bulk_edit',
  EXPORT_BOOKING: 'export_booking',
  ADD_MANUAL_BOOKING: 'add_manual_booking',
  VIEW_BOOKING_DETAILS: 'view_booking_details',
  MANAGE_BOOKING: 'manage_booking',
  READ_FAQ_STAFF: 'read_faq_staff',
  HELP_IMPORT_STAFF: 'help_import_staff',
  IMPORT_STAFF: 'import_staff',
  ADD_STAFF: 'add_staff',
  EDIT_STAFF: 'edit_staff',
  DUPLICATE_STAFF: 'duplicate_staff',
  REMOVE_STAFF: 'remove_staff',
  VIEW_CALENDAR: 'view_calendar',
  VIEW_BOOKING: 'view_booking',
  EDIT_BOOKING: 'edit_booking',
  TOGGLE_LOCK_TIMEZONE: 'toggle_lock_timezone',
  TOGGLE_ENABLE_BUSINESS_HOURS: 'toggle_enable_business_hours',
  ADD_DATE_OVERRIDES: 'add_date_overrides',
  ADD_HOLIDAY: 'add_holiday',
  CUSTOMIZE_COLOR: 'customize_color',
  TOGGLE_SHOW_TIMEZONE: 'toggle_show_timezone',
  TOGGLE_SKIP_CART: 'toggle_skip_cart',
  CUSTOMIZE_TEXT: 'customize_text',
  ENABLE_WIDGET: 'enable_widget',
  REMOVE_MEETY_BRANDMARK: 'remove_meety_brandmark',
  CONNECT_TO_INTEGRATE: 'connect_to_integrate',
  READ_FAQ_INTEGRATE: 'read_faq_integrate',
  SYNC_TO_INTEGRATION: 'sync_to_integration',
  DISCONNECT_INTEGRATION: 'disconnect_integration',
  REQUEST_FEATURE: 'request_feature',
  CHAT_WITH_SUPPORT: 'chat_with_support',

  POPUP_SUBS_PLAN: 'plan_popup_open',
  TOGGLE_ENABLE_WAITLIST: 'toggle_enable_waitlist',

  CREATE_SUBSCRIPTION_BUTTON_PRESSED: 'create_subscription_button_pressed',
  CHOOSE_PLAN_BUTTON_PRESSED: 'choose_plan_button_pressed',

  OB_SKIP: 'ob_skip',
  OB_CHOOSE_PRICING: 'ob_choose_pricing',
  OB_SELECT_TYPE_OF_BOOKING: 'ob_select_type_of_booking',
  OB_SELECT_SLOT_TYPE: 'ob_select_slot_type',
  OB_SELECT_TIME_SLOT_DISPLAY: 'ob_select_time_slot_display',
  OB_ACTIVATE_MEETY_WIDGET: 'ob_activate_meety_widget',
  OB_CONTACT_SUPPORT: 'ob_contact_support',
  OB_ABANDON_ONBOARDING: 'ob_abandon_onboarding',
  OB_CONTACT_SUPPORT_SETUP: 'ob_contact_support_setup',

  CONTACT_LIVE_AGENT: 'contact_live_agent',
};
