import type { AnyAction, CombinedState } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { appApi } from './apis/BaseApi';
import navigationHistorySlice from './slices/navigationHistorySlice';
import popupSlice from './slices/popupSlice';
import settingsSlice from './slices/settingsSlice';

interface ReducerState {
  [popupSlice.name]: ReturnType<typeof popupSlice.reducer>;
  [settingsSlice.name]: ReturnType<typeof settingsSlice.reducer>;
  [appApi.reducerPath]: ReturnType<typeof appApi.reducer>;
  [navigationHistorySlice.name]: ReturnType<
    typeof navigationHistorySlice.reducer
  >;
}

const sliceReducer = {
  [popupSlice.name]: popupSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [appApi.reducerPath]: appApi.reducer,
  [navigationHistorySlice.name]: navigationHistorySlice.reducer,
};

const rootReducer = (
  state: ReducerState,
  action: AnyAction
): CombinedState<ReducerState> => {
  // Doc: https://github.com/kirill-konshin/next-redux-wrapper#state-reconciliation-during-hydration
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    return nextState;
  }
  return combineReducers(sliceReducer)(state, action);
};

export default rootReducer;
