import { Box, Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { memo, useCallback, useMemo } from 'react';
import { useCrisp } from '../../hooks/useCrisp';
import useRouter from '../../hooks/useRouter';
import { BaseProps } from '../../types/settings-type';

interface Props extends BaseProps {
  page?: string;
}

function PageFooter({ page }: Props) {
  const [i18n] = useI18n();
  const router = useRouter();
  const crisp = useCrisp();

  const getFooterText = useCallback(
    (url: string, featureKey: string) => {
      return i18n.translate('Common.Footer.footerText', {
        app_help: (
          <Link
            onClick={() => {
              crisp.openChat();
            }}
          >
            {i18n.translate('Common.Footer.usingTheApp')}
          </Link>
        ),
        feature_help: (
          <Link url={url} target="_blank">
            {i18n.translate(featureKey)}
          </Link>
        ),
      });
    },
    [crisp, i18n]
  );

  const footerText = useMemo(() => {
    const FOOTER_SERVICE_TAB = [
      {
        path: '/services',
        url: 'https://meetyapp.io/docs/services/regular-booking-in-meety/',
        featureKey: 'Common.Footer.services',
        tab: [0],
      },
      {
        path: '/services',
        url: 'https://meetyapp.io/docs/services/subscription-booking-in-meety/',
        featureKey: 'Common.Footer.createSubscription',
        tab: [1],
      },
    ];
    const FOOTER_BOOKING_TAB = [
      {
        path: '/bookings',
        url: 'https://meetyapp.io/docs/bookings/manage-your-bookings-data-in-meety/',
        featureKey: 'Common.Footer.bookings',
        tab: [0, 1, 2],
      },
      {
        path: '/bookings',
        url: 'https://meetyapp.io/docs/services/bundle-booking-in-meety/',
        featureKey: 'Common.Footer.manageBundle',
        tab: [3],
      },
      {
        path: '/bookings',
        url: 'https://meetyapp.io/docs/services/subscription-booking-in-meety/',
        featureKey: 'Common.Footer.manageSubscription',
        tab: [4],
      },
      {
        path: '/bookings',
        url: 'https://meetyapp.io/docs/services/deposit-payment-in-meety/',
        featureKey: 'Common.Footer.managingDeposits',
        tab: [5],
      },
      {
        path: '/bookings',
        url: 'https://meetyapp.io/docs/services/waitlist-in-meety/',
        featureKey: 'Common.Footer.managingWaitlist',
        tab: [6],
      },
    ];
    const FOOTER_LINK: {
      path: string;
      url: string;
      featureKey: string;
      tab?: number[];
    }[] = [
      {
        path: '/services/create-subscription',
        url: 'https://meetyapp.io/docs/services/subscription-booking-in-meety/',
        featureKey: 'Common.Footer.createSubscription',
      },
      {
        path: '/services/subscription-manage',
        url: 'https://meetyapp.io/docs/services/subscription-booking-in-meety/',
        featureKey: 'Common.Footer.createSubscription',
      },
      {
        path: '/bookings/subscription-manage',
        url: 'https://meetyapp.io/docs/services/subscription-booking-in-meety/',
        featureKey: 'Common.Footer.manageSubscription',
      },
      {
        path: '/dashboard',
        url: 'https://meetyapp.io/docs/dashboard/dashboard-overview/',
        featureKey: 'Common.Footer.dashboard',
      },
      ...FOOTER_SERVICE_TAB,
      {
        path: '/services',
        url: 'https://meetyapp.io/docs/services/regular-booking-in-meety/',
        featureKey: 'Common.Footer.services',
      },
      ...FOOTER_BOOKING_TAB,
      {
        path: '/bookings',
        url: 'https://meetyapp.io/docs/bookings/manage-your-bookings-data-in-meety/',
        featureKey: 'Common.Footer.bookings',
      },
      {
        path: '/staff',
        url: 'https://meetyapp.io/docs/teams/manage-your-teams-data-in-meety/',
        featureKey: 'Common.Footer.staff',
      },
      {
        path: '/calendar',
        url: 'https://meetyapp.io/docs/calendar/manage-your-calendar-in-meety/',
        featureKey: 'Common.Footer.calendar',
      },
      {
        path: '/shops/widgets',
        url: 'https://meetyapp.io/docs/settings/website-widget-video-tutorial-in-meety/',
        featureKey: 'Common.Footer.websiteWidget',
      },
      {
        path: '/shops',
        url: 'https://meetyapp.io/docs/settings/general-settings-in-meety/',
        featureKey: 'Common.Footer.settings',
      },
    ];
    const pathName = router.pathname;
    const selectedTab = Number(router.query.selectedTab);

    const footerLink = FOOTER_LINK.find(
      (obj) =>
        pathName.includes(obj.path) &&
        (obj?.tab !== undefined
          ? obj.tab.find((tab) => tab === selectedTab) === selectedTab
          : true)
    );
    if (footerLink) {
      return getFooterText(footerLink.url, footerLink.featureKey);
    }
    return '';
  }, [getFooterText, router.pathname, router.query.selectedTab]);

  return (
    <Box paddingBlockEnd="1200">
      <Text as="p" variant="bodyMd" tone="subdued">
        {footerText}
      </Text>
    </Box>
  );
}

export default memo(PageFooter);
