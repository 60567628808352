import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';
import popupSlice from '../slices/popupSlice';

export interface BaseRequestParams {
  isBlob?: boolean;
}

export class ErrorResponse {
  constructor(
    readonly statusCode: number,
    readonly errorCode: number,
    readonly message: string
  ) {}
}

export type BaseResponse<T = any> = {
  body: T;
  message: string;
};

export type BodyResponse<T = any> = {
  body: T;
};

export type DataResponse<T = any> = {
  data: T;
};

export type PaginationResponse<T = any> = {
  body: T;
  meta: Meta;
};

export type Meta = {
  pagination: Pagination;
};

export type Pagination = {
  currentPage: number;
  limit: number;
  total: number;
  totalPages: number;
};

export class AuthenticatedFetch {
  private static _fetch:
    | ((uri: RequestInfo, options?: RequestInit) => Promise<Response>)
    | null;

  static set(
    fetch:
      | ((uri: RequestInfo, options?: RequestInit) => Promise<Response>)
      | null
  ) {
    this._fetch = fetch;
  }
  static get(): (uri: RequestInfo, options?: RequestInit) => Promise<Response> {
    return (
      this._fetch ??
      (async (uri: RequestInfo, options?: RequestInit) => {
        const response = await fetch(uri, options);

        if (
          response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') ===
          '1'
        ) {
          const reauthorizeUrl = response.headers.get(
            'X-Shopify-API-Request-Failure-Reauthorize-Url'
          );
          if (reauthorizeUrl) {
            window.open(reauthorizeUrl, '_top');
          }

          return Promise.reject(new ErrorResponse(403, 403, 'Unauthorized.'));
        }

        return response;
      })
    );
  }
}

export const GET = async <Res>(api: string, arg: BaseRequestParams) => {
  const fetch = AuthenticatedFetch.get();
  const { isBlob, ...params } = arg;

  try {
    const searchParams = getSearchParams(params);

    const response = await fetch(`${api}?${searchParams.toString()}`);

    if (isBlob) {
      return handleResponseBlob(response);
    }
    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const POST = async <Res>(api: string, arg: BaseRequestParams) => {
  const fetch = AuthenticatedFetch.get();
  const body = arg;

  try {
    const response = await fetch(api, {
      method: 'POST',
      body: JSON.stringify(body || {}),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const PATCH = async <Res>(api: string, arg: BaseRequestParams) => {
  const fetch = AuthenticatedFetch.get();
  const body = arg;

  try {
    const response = await fetch(api, {
      method: 'PATCH',
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const DELETE = async <Res>(api: string, arg: BaseRequestParams) => {
  const fetch = AuthenticatedFetch.get();

  try {
    const response = await fetch(api, {
      method: 'DELETE',
      body: arg ? JSON.stringify(arg) : undefined,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

export const UPLOAD = async <Res>(
  api: string,
  arg: BaseRequestParams & { body?: FormData }
) => {
  const fetch = AuthenticatedFetch.get();
  const { body } = arg;
  try {
    const response = await fetch(api, {
      method: 'POST',
      body: (body as BodyInit) || null,
    });

    return handleResponseJson<Res>(response);
  } catch (error) {
    return handleError(error);
  }
};

function getSearchParams(params: object): URLSearchParams {
  const searchParams = new URLSearchParams();
  if (params) {
    _.keys(params).forEach((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        value.forEach((v) => {
          searchParams.append(key, `${v}`);
        });
      } else if (value !== undefined) {
        searchParams.append(key, `${value}`);
      }
    });
  }
  return searchParams;
}

async function handleResponseBlob(response: Response) {
  try {
    const blob = await response.blob();

    return blob;
  } catch (error) {
    return handleError(error);
  }
}

async function handleResponseJson<Res>(response: Response) {
  try {
    const json = await response.json();

    const { statusCode, errorCode, message } = json;
    if (errorCode) {
      return Promise.reject(new ErrorResponse(statusCode, errorCode, message));
    }

    return json as Res;
  } catch (error) {
    return handleError(error);
  }
}

function handleError(error: ErrorResponse | Error | string) {
  if (error instanceof ErrorResponse) {
    return Promise.reject(error);
  }
  if (error instanceof Error) {
    const { message } = error;
    return Promise.reject(new ErrorResponse(-1, -1, message));
  }
  return Promise.reject(new ErrorResponse(-1, -1, error));
}

export const appBaseQuery = (): BaseQueryFn<
  {
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'UPLOAD';
    url: string;
    arg: BaseRequestParams;
  },
  unknown,
  ErrorResponse
> => {
  return async ({ method, url, arg }, { dispatch }) => {
    try {
      switch (method) {
        case 'GET': {
          const data = await GET(url, arg);
          return { data };
        }
        case 'POST': {
          const data = await POST(url, arg);
          return { data };
        }
        case 'PATCH': {
          const data = await PATCH(url, arg);
          return { data };
        }
        case 'DELETE': {
          const data = await DELETE(url, arg);
          return { data };
        }
        case 'UPLOAD': {
          const data = await UPLOAD(url, arg);
          return { data };
        }
      }
    } catch (error) {
      //Ignore some error code here
      if (
        ![403, 429].includes(error?.statusCode) &&
        !['BOOKING_DEPOSIT_IS_DONE', 'PREVIEW_VARIANT_NOT_FOUND'].includes(
          error?.message
        )
      ) {
        dispatch(popupSlice.actions.showErrorPopup(error.message));
      }
      return { error };
    }
  };
};

export const appApi = createApi({
  baseQuery: appBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
  tagTypes: [
    'all',
    'shop',
    'services',
    // 'bookings',
    'staffs',
    'customers',
    'calendar',
    'analytics',
    'masterdata',
    'widgets',
  ],
});
