import { JwtPayload, decode } from 'jsonwebtoken';
import { ErrorResponse } from '../redux/apis/BaseApi';
import { isServer } from './is-server';

const LATENCY = 5;

export function createAuthenticatedFetch(idToken: string) {
  if (!isServer() || !idToken) {
    return null;
  }

  try {
    const idTokenPayload = decode(idToken) as JwtPayload;
    if (
      !idTokenPayload.exp ||
      idTokenPayload.exp <= Date.now() / 1000 - LATENCY
    ) {
      return null;
    }
  } catch (error) {
    console.log(`Failed to parse id_token='${idToken}': ${error}`);
    return null;
  }

  return async (uri: RequestInfo, options?: RequestInit) => {
    const response = await fetch(`${process.env.API_URL}${uri}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (
      response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1'
    ) {
      return Promise.reject(new ErrorResponse(403, 403, 'Unauthorized.'));
    }

    return response;
  };
}
