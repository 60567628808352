export enum NotificationGroupEnum {
  Confirmation = 1,
  Reminder = 2,
  Reschedule = 3,
  Cancelation = 4,
  CalendarIntegration = 5,
  Recursive = 6,
  Waitlist = 7,
}

export enum NotificationTypeEnum {
  Successful = 1,
  UpdateDetail = 2,
  ReminderNext = 3,
  RescheduleConflict = 4,
  Rejected = 5,
  ReminderUpcomming = 6,
  FollowUp = 7,
  Rescheduled = 8,
  Cancelled = 9,
  // RecurringBooking
  SubscriptionActive = 12,
  SubscriptionPaymentFail = 13,
  SubscriptionCancel = 14,
  SubscriptionResume = 15,
  SubscriptionPause = 16,
  SubscriptionExprie = 17,
  SubscriptionBillingUpdate = 18,
  SubscriptionUpcommingPaymentOrBillingCYcle = 19,
}

export enum NotiParamGroupEnum {
  General = 1,
  Bundle = 2,
  Subscription = 3,
  Waitlist = 4,
}

export enum IntegType {
  GOOGLE_CALENDAR = 1,
  OUTLOOK_CALENDAR = 2,
  ZOOM = 3,
  MSTEAM = 4,
  CALENDLY = 5,
}

export enum InputType {
  textField = 0,
  dropdown = 1,
  radio = 2,
  checkbox = 3,
  email = 4,
  phone = 5,
  date = 6,
  time = 7,
}

export enum BookingTypeEnum {
  HourBasedBooking = 1,
  BundleBooking = 2,
  DayBasedBooking = 3,
  InstantBooking = 4,
}

export enum ServiceBookingTypeEnum {
  Regular = 1,
  Bundle = 2,
  Instant = 4,
}

export enum SubsDurationUnit {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  Yearly = 4,
  Hourly = 8,
}

export enum MeetyTimeUnit {
  Minute = 0,
  Hour = 1,
  Day = 2,
  Week = 4,
  Month = 8,
}

export enum SubscriptionContractStatus {
  // The contract is active and continuing per its policies.
  ACTIVE = 1,
  // The contract is temporarily paused and is expected to resume in the future.
  PAUSED = 2,
  // The contract has ended per the expected circumstances. All billing and deliverycycles of the subscriptions were executed.
  EXPIRED = 3,
  // The contract was ended by an unplanned customer action.
  CANCELLED = 4,
  // The contract ended because billing failed and no further billing attempts are expected.
  FAILED = 5,
  // The contract has expired due to inactivity.
  STALE = 6,
}

export enum BookingStatus {
  DELETED = -2,
  ANY = -1,
  Draft = 0,
  Active = 1, //Approved
  Completed = 2, //fulfilled
  CustomerCheckedIn = 3,
  Canceled = 4,
  InProgressFulfill = 5,
  PlaceHolderExpired = 6,
  Pending = 7,
  Rejected = 8,
  NoShow = 9,
  Arrived = 10,
  InProgressCancel = 11,
}

export enum BookingDepositStatus {
  NOT = 0,
  FullPaid = 1,
  PartiallyPaid = 2,
  Pending = 3,
  Expired = 4,
}

export enum BookingPaidStatus {
  UnPaid = 0,
  Paid = 1,
  PartiallyPaid = 2,
  Pending = 3,
}

export enum FREQUENCY_TYPE {
  day = 1,
  week = 2,
  month = 3,
  year = 4,
}

export enum DISCOUNT_TYPE {
  percent = 1,
  fixed = 2,
  amount = 3,
}

export enum WidgetPlatform {
  MOBILE = 0,
  DESKTOP = 1,
}

/**
 * Pending = 0,
  Confirmed = 1,
  Rejected = 2,
  Expired = 4,
 */
export enum WaitlistStatus {
  PENDING = 0,
  CONFIRMED = 1,
  REJECTED = 2,
  EXPIRED = 4,
}

export enum TabIndex {
  BOOKING = 1,
  BUNDLE = 2,
  SUBSCRIPTION = 3,
  DEPOSIT = 4,
  WAITLIST = 5,
}

export enum TabBookingIndex {
  UPCOMING = 1,
  PAST = 2,
  CANCELLED = 3,
}

export enum TabBundleIndex {
  CURRENT = 1,
  ACTIVE = 2,
  COMPLETED = 3,
  EXPIRED = 4,
  CANCELLED = 5,
}

export enum TabSubscriptionIndex {
  CURRENT = 0,
  ACTIVE = SubscriptionContractStatus.ACTIVE,
  PAUSED = SubscriptionContractStatus.PAUSED,
  EXPIRED = SubscriptionContractStatus.EXPIRED,
  CANCELLED = SubscriptionContractStatus.CANCELLED,
}

export enum TabDepositIndex {
  ALL = 1,
  PARTIALLY_PAID = 2,
  PENDING_PAYMENT = 3,
  UNPAID = 4,
  PAID = 5,
}

export enum TabWaitlistIndex {
  ALL = -1,
  PENDING = WaitlistStatus.PENDING,
  CONFIRMED = WaitlistStatus.CONFIRMED,
  REJECTED = WaitlistStatus.REJECTED,
  EXPIRED = WaitlistStatus.EXPIRED,
}

export enum ChargeTimeUnit {
  Day = 1,
  Hour = 2,
  Minute = 3,
}
